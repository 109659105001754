<template>
  <div class="ma-0 pa-0 bg-white rounded-lg">
    <iframe
      :src="token"
      ref="iframe"
      class="custom-content-height"
      style="width: 100% !important"
      frameborder="0"
      width="100%"
      height="100%"
    >
    </iframe>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
export default {
  name: "Scheduler",
  computed: {
    token: function () {
      return `${
        process.env.VUE_APP_BASE_URL
      }/data-sync/notifications?frameToken=${getToken()}`;
    },
  },
};
</script>
